<template>
  <div class="Plans">
    <CXPlansSection
      v-if="arePaymentPlansFetched"
      :contained="false"
      bottom-section-classes="p-l-xl p-r-xl"
    >
      <div
        slot="TopSection"
        class="p-b-xl"
      />
    </CXPlansSection>

    <div class="p-l-xl">
      <CancelPaymentPlanButton
        v-if="!subscription.isFreemium"
        :organisation-id="firstOrganisation.id"
        :is-cancelled="!!subscription.canceledAt"
        class="m-t-l"
        @change="fetchUserOrganisationsRequest"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import CXPlansSection from '@/components/cx/pricing/CXPlansSection'
import CancelPaymentPlanButton from '@/components/billing/CancelPaymentPlanButton'

export default {
  components: {
    CancelPaymentPlanButton,
    CXPlansSection
  },

  computed: {
    ...mapGetters(['firstOrganisation', 'subscription']),

    ...mapGetters('cx/payment', ['arePaymentPlansFetched'])
  },

  mounted () {
    this.fetchPlans()
  },

  methods: {
    ...mapActions(['fetchUserOrganisationsRequest']),

    ...mapActions('cx/payment', ['fetchPlans'])
  }
}
</script>
