<template>
  <v-button
    :loading="isCancellingSubscription"
    class="CancelPaymentPlanButton is-dark is-small"
    data-testid="CancelPaymentPlanButton"
    @click="toggleCancel"
  >
    {{ buttonText }}
  </v-button>
</template>

<script>
import { BillingApiService } from '@/services/api/BillingApiService'

/**
 * Renders a button for toggling the Plan's cancelled status
 * @module CancelPaymentPlanButton
 */
export default {
  name: 'CancelPaymentPlanButton',

  props: {
    isCancelled: {
      type: Boolean,
      default: false
    },
    organisationId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isCancellingSubscription: false
    }
  },

  computed: {
    buttonText () {
      const type = this.isCancelled ? 'reactivate' : 'cancel'
      return this.$t(`components.billing.cancel_payment_plan_button.${type}`)
    }
  },

  methods: {
    /**
     * Toggles between Cancelling and Reactivating a plan
     * @returns {Promise}
     */
    toggleCancel () {
      if (this.isCancelled) {
        return this.reActivateSubscription()
      }
      return this.cancelSubscription()
    },
    /**
     * Cancels the currently active subscription and reverts to the Freemium plan
     * @returns {Promise<void>}
     */
    async cancelSubscription () {
      try {
        await this.$confirm(this.$t('components.billing.cancel_payment_plan_button.confirm_subscription_cancel'))
        this.isCancellingSubscription = true
        await BillingApiService.cancel(this.organisationId)
        this.$emit('change')
        this.$notify.success(this.$t('success.subscription_cancelled'))
      } catch (e) {
        if (e === 'cancel') return
        this.$displayRequestError(e)
      } finally {
        this.isCancellingSubscription = false
      }
    },
    /**
     * Reactivates the Subscription Plan
     * @returns {Promise<void>}
     */
    async reActivateSubscription () {
      try {
        await this.$confirm(this.$t('components.billing.cancel_payment_plan_button.confirm_subscription_reactivate'))
        this.isCancellingSubscription = true
        await BillingApiService.reactivate(this.organisationId)
        this.$emit('change')
        this.$notify.success(this.$t('success.subscription_reactivated'))
      } catch (e) {
        if (e === 'cancel') return
        this.$displayRequestError(e)
      } finally {
        this.isCancellingSubscription = false
      }
    }
  }
}
</script>
